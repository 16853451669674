


















































































import ShopAPI from "../../common/api/api_shop";
import Vue from "vue";
import _ from "lodash";
import store from "../../store/shop";
import notificationMixin from "../../mixin/notification";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import * as constant from "../../common/util/constant";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

Vue.directive('enhance-defaulot-value', {
  bind: function (el) {
    el.addEventListener('focus', function() {
      const value = el.value;
      if (value == 0) {
        el.value = '';
      }
    });
    el.addEventListener('blur', function() {
      const value = el.value;
      if (value == '') {
        el.value = 0;
      }
    });
  }
});

export default Vue.component("Category", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QError: () => import("../../components/elements/QError.vue"),
    QLink: () => import("../../components/elements/QLink.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  data() {
    return {
      categories: [],
      category_group_temps: [],
      category_groups: [],
      errors: "",
      mode: "",
      isLoading: false,
    };
  },
  mixins: [notificationMixin],
  mounted() {
    api.set_token(store.state.token);
    this.fetchCategories();
    store.commit("setSelectedCategory", {});
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
  watch: {
    menuChange(newVal, oldVal) {
      console.log('menuChange', newVal);
      if (newVal != '') {
        this.fetchCategories();
      }
    }
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    menuChange() {
      return store.state.pageChange.menu;
    },
    isModalReset(): boolean {
      return this.mode === "reset";
    },
  },
  methods: {
    handleClickReset: function () {
      if (this.isLoading) {
        return;
      }

      this.mode = "reset";
    },
    handleClickSubmit: function () {
      if (this.isLoading) {
        return;
      }
      this.mode = "";
      let message = "";
      this.isLoading = true;
      api
        .soldout_all()
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.fetchCategories();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    handleClickCloseEditor: function () {
      this.closeEditor();
    },
    closeEditor: function () {
      this.mode = "";
      this.isLoading = false;
    },

    setCategories() {
      if (!this.categories) {
        return;
      }

      store.commit("setCategories", this.categories);
    },
    setCategoryGroups() {
      if (!this.category_group_temps) {
        return;
      }

      store.commit("setCategoryGroups", this.category_group_temps);
    },
    fetchCategories() {
      this.isLoading = true;
      let message = "";
      api
        .soldout_index()
        .then((response) => {
          if (response.data.status !== "success") {
            message = response.data.message || "データの取得に失敗しました。";
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.categories = response.data.data.categories;
          this.category_group_temps = response.data.data.category_groups;
          this.category_groups = [];
          _.forEach(this.category_group_temps, (category_group) => {
            if(!this.hasValidCategory(category_group)){
              return;
            }

            if (!category_group.parent_id) {
              category_group.type = 1;

              if (category_group.category_id) {
                let category = this.getCategory(category_group.category_id)
                category_group.name = category.name;
                category_group.type = category.type;
              }

              category_group.link = `/${this.prefix}/${this.shoppix}/soldout/categories/${category_group.category_id}`;

              if (this.hasChild(category_group.id)) {
                category_group.link = `/${this.prefix}/${this.shoppix}/soldout/category_group/${category_group.id}`;
              }

              this.category_groups.push(category_group);
            }
          });

          this.category_groups = _.orderBy(this.category_groups, ['rank'],['asc']);
          this.setCategoryGroups();
          this.setCategories();
        })
        .catch((err) => {
          this.isLoading = false;
          message = err.response.data.message || "データの取得に失敗しました。";
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    getCategory(id){
      return _.find(this.categories, (category) => category.id === id);
    },
    hasChild(id){
      let result = false;

      let child = _.find(this.category_group_temps, (category_group) => category_group.parent_id === id);

      return !!child;
    },
    hasValidCategory(category_group) {
      let result = false;

      if (category_group.category_id) {
        let category = this.getCategory(category_group.category_id);

        if (category) {
          result = true;
        }
      }

      let childs = _.filter(this.category_group_temps, (categoryGroup) => categoryGroup.parent_id === category_group.id);

      _.forEach(childs, (child) => {
        result = result ? result : this.hasValidCategory(child);
      });

      return result;
    },
    toCatStr(catId: number) {
      switch (catId) {
        case 1:
          return "";
        case 2:
          return "takeout";
        case 3:
          return "catering";
      }

      return "";
    },
    getMenuNameById(id) {
      const result = _.chain(this.categories)
        .map("menus")
        .flatten()
        .filter({ id: id })
        .value();

      if (result[0]) {
        return result[0]["name"];
      }

      return null;
    },
  },
  filters: {
  },
});
